
import React from "react"

import Layout from "../components/layout"

export default () =>
<Layout>
	 <div id="content">
<h1>Fotos</h1>
<img src="http://servati.us/images/_IRM0368.JPG" alt="die wunderschoene Bratsche" width="400"/><br/><br/><br/>
<img src="http://servati.us/images/img_5742.JPG" alt="Irma unterwegs" width="400"/><br/>Foto von Nick Sherwood<br/><br/><br/>
<img src="http://servati.us/images/img_5747.JPG" alt="Irma nachdenklich" width="400"/><br/>Foto von Nick Sherwood<br/><br/><br/>
<img src="http://servati.us/images/IMGP6084a.JPG" alt="Irma und der Respiro Trio" width="400"/><br/><br/><br/>
<img src="http://servati.us/images/IRMA7185a.JPG" alt="Irma beim ueben" width="400"/><br/><br/><br/>
<img src="http://servati.us/images/IRMA7353.JPG" alt="Irma unterrichtet" width="400"/><br/><br/><br/>
<img src="http://servati.us/images/IRMA9194.JPG" alt="Irma festlich" width="400"/><br/><br/><br/>
  	 </div>
</Layout>
